'use strict';

Gri.module({
  name: 'carousel-delta',
  ieVersion: null,
  $el: $('.carousel-delta'),
  container: '.carousel-delta',
  fn: function () {
    var owl = $('.carousel-delta .owl-carousel');

    owl.owlCarousel({
      // autoplay:true,
      // autoplayTimeout:3000,
      items: 1,
      dots: true,
      // nav:true,
      // navText: ["<i class='icon-left-arrow'>", "<i class='icon-right-arrow'>"],
      singleitem: true
    });
    owl.on('changed.owl.carousel', function(event) {
      var item = event.item.index;
      var imgSrc = $(event.target).find(".owl-item").eq(item).find(".box").data('img');
      $(".slide-img").attr("src",imgSrc);

      var color = $(".color-box");
      color.removeClass("active");
      $("#" + item).addClass("active");
    });

    var imgSrc = $(".owl-item.active .box").data("img");
    $(".slide-img").attr("src",imgSrc);


    $(document).ready(function () {
      if( $('.product-link-hidden ul .active').next().text() == ""){
        $('.product-link .next a').attr("href", $('.product-link-hidden ul li:first-child').text());
        $('.product-link .prev a').attr("href", $('.product-link-hidden ul .active').prev().text())
      }else if($('.product-link-hidden ul .active').prev().text() == ""){
        $('.product-link .prev a').attr("href", $('.product-link-hidden ul li:last-child').text());
        $('.product-link .next a').attr("href", $('.product-link-hidden ul .active').next().text())
      }else{
        $('.product-link .prev a').attr("href", $('.product-link-hidden ul .active').prev().text());
        $('.product-link .next a').attr("href", $('.product-link-hidden ul .active').next().text())
      }


    });
  }
});
